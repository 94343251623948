* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: "sfui-regular";
}

@font-face {
	font-family: sfui-regular;
	src: url('../../assets/fonts/sfuitext-regular.ttf');
}

@font-face {
	font-family: sfui-semibold;
	src: url('../../assets/fonts/sf-ui-display-semibold.ttf');
}

html {
	scroll-behavior: smooth;
}

body {
	background: #fff;
	color: #434343;
	font-size: 14px;
	overflow-x: hidden;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	overflow-y: auto;
	appearance: none;
	font-family: "sfui-regular";
}

a {
	color: #434343;
	text-decoration: none;
}

a:focus,
a:hover {
	text-decoration: none;
}

.font-normal {
	font-family: "sfui-regular";
}

.pointer {
	cursor: pointer;
}

*,
input,
select,
textarea,
option,
button {
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5 {
	width: 100%;
	padding-bottom: 10px;
}

.card_box_item h3 {
	letter-spacing: 0px;
	font-weight: normal;
	font-family: "sfui-semibold";
	padding-bottom: 0;
}

p {
	width: 100%;
}

ul {
	list-style: none;
}

.w50 {
	width: 50%;
	float: left;
	padding: 10px 0 0;
}

.flex_row {
	display: flex;
	align-items: center;
}

.grid-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 33% 33% 32%;
}

.cod-popup app-codhome {
	float: left;
	width: 100%;
	height: calc(100% - 45px);
	overflow-y: auto;
}

.cod-popup main {
	width: 100%;
	float: left;
	padding-bottom: 20px;
}

.cod-popup .main {
	width: 100%;
	padding: 10px 15px 0;
	float: left;
}

.tabordion-list {
	width: 150px;
	border-right: 2px solid #0d7836;
}

.tabordion-list li {
	cursor: pointer;
	font-size: 16px;
	padding: 20px 20px;
	color: #bbb;
	font-weight: bold;
}

.tabordion-output {
	width: calc(100% - 150px);
	padding: 0 0 0 40px;
}

.tabordion-list li.active {
	background: #eee;
	color: #000;
}

.grid-container-two {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 64% 33%;
}

.grid-box {
	padding: 10px 0 0;
}

.grid-payment {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 28% 45% 25%;
	width: 100%;
}

td.upload-support-doc {
	width: 75%;
}

td.upload-support-doc-btn {
	width: 25%;
}


.grid-date {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
}

.grid-payment-box {
	border: 1px solid #cacbca;
	border-bottom: 5px solid #0d7836;
	border-radius: 4px;
	position: relative;
}

.grid-two {
	display: grid;
	grid-column-gap: 15px;
	grid-template-columns: 49% 49%;
	width: 100%;
}

.grid-two-box {
	padding: 10px 0 0;
}

.grid-one-third {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 68% 30%;
}

.grid-two-box form {
	padding: 0 12px 0 0;
}

.grid-third {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 32% 32% 32%;
}

.grid-modal-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 42% 42% 20%;
}

.grid-modal-box {
	padding: 10px 0 0;
}

.profile_container {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 15% 41% 41%;
}

.profile_box {
	padding-right: 10px;
	border-right: 1px solid #9d9d9d;
}

.profile_box:last-child {
	border-right: 0;
}

.dropdown-menu-top li {
	margin-top: 0 !important;
}

img.logo {
	width: 172px;
}

.toast-success {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.toast-success button {
	order: 2;
}

.login_nav {
	display: block !important;
	float: right;
	padding: 0 !important;
}

.visatype_box {
	padding: 20px 0;
}

.catgories_box {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.09), 0 4px 6px rgba(0, 0, 0, 0.09);
	padding: 17px;
}

.eligible_box {
	width: 100%;
	float: left;
	padding-bottom: 60px;
}

.catgories h3,
.eligible_box h3 {
	font-size: 18px;
	padding-bottom: 12px;
}

.tabordion {
	position: relative;
	display: flex;
	margin-bottom: 20px;
}

.eligible_box input {
	float: right;
}

.catgories .save_btn {
	margin: 20px 0 !important;
}

.dropdown-menu-top li {
	margin-top: 0 !important;
}

header {
	position: fixed;
	background: #fff;
	width: 100%;
	z-index: 999;
	top: 0;
	left: 0;
	border-bottom: 1px solid #dfdfdf;
	padding: 5px 0 0;
}

.header_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.nav-links {
	list-style: none;
	width: 100%;
	text-transform: capitalize;
	text-align: right;
	display: block;
}

.nav-links li a {
	text-decoration: none;
}

.nav-links img {
	width: 19px;
}

.nav-links li a:hover {
	text-shadow: 0 0 #fff;
}

.nav-links li {
	position: relative;
	display: inline-block;
	margin-left: 15px;
}

.dash img {
	width: 14px;
	height: auto;
}

.nav-links li a:hover::before {
	width: 80%;
}

.adjust-height {
	min-height: 600px;
}

.header_box {
	display: flex;
	align-items: center;
	width: 220px;
}

.header_box.menu {
	width: calc(100% - 480px);
	justify-content: flex-end;
}

.nav-txt {
	max-width: 260px;
	width: 100%;
	padding-left: 10px;
}

.w_auto {
	width: auto !important;
	float: left;
}

.dropdown a:hover {
	color: #fff !important;
}

.fix_height {
	height: 140px;
}

.dropdown {
	width: auto;
	position: relative;
	margin-top: 0 !important;
}

ul li ul.dropdown-menu-top {
	width: 100%;
	background: #fff;
	display: none;
	position: absolute;
	z-index: 999;
	text-align: center;
	border: 1px solid #0D7836;
	float: left;
}

ul li ul.dropdown-menu-top li {
	display: block;
	margin: 3px 0 !important;
}

.cdk-overlay-pane .mat-menu-panel {
	min-width: 138px;
	min-height: 40px;
}

.mat-focus-indicator.mat-menu-item {
	line-height: 23px;
}

.cdk-overlay-pane .mat-menu-panel .mat-menu-content:not(:empty) {
	padding-top: 0;
	padding-bottom: 0;
}

.header_box span {
	padding-right: 6px;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.header_box .nav-txt {
	max-width: 230px;
	width: 100%;
}

.hamburger {
	display: none;
}

.header_box img {
	width: auto;
	max-width: 100%;
}

hr.header_line {
	width: 0;
	margin: 0 auto;
	color: #CACBCA;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
	height: 50px;
	border-left: 2px dotted #fff;
}

.email-label {
	width: 100%;
	float: left;
	padding: 5px 0 13px;
}

.email-label label {
	width: auto;
	font-size: 13px;
}

.email-label span {
	width: auto;
	float: left;
	line-height: normal;
	padding-left: 3px;
}

.profile_type .form_box {
	height: 65px;
}

.profile_type .grid-two-box {
	padding: 0 !important;
}

.dropdown-menu-top li:after {
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	margin: 2px 0;
	background: #0d7836;
}

.dropdown .custom_btn {
	margin: 10px 10px 0 0;
	padding: 6px 6px !important;
}

.dropdown-menu-top li:last-child:after {
	background: none;
	margin: 2px 0;
}

table.request_table {
	border: 1px solid #cacbca;
}

.request-detail .form-card {
	width: 100%;
}

table.request_table tr {
	float: none;
	display: table-row;
	border-bottom: 1px solid #cacbca;
	width: auto;
}

table.request_table tr:last-child {
	border-bottom: none;
}

table.request_table td {
	display: table-cell;
	text-align: left;
	width: auto;
	float: none;
}

.custom_btn1 {
	background: #0d7836;
	color: #fff;
	padding: 5px 8px;
	border-radius: 2px;
	width: auto;
	display: block;
	border: none;
	border-radius: 3px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	min-width: 60px;
}

.track_btn {
	background: #f96922;
	color: #fff !important;
	padding: 8px 8px;
	font-size: 14px;
	border: none;
	border-radius: 3px;
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	min-width: 60px;
	display: inline-block;
	min-height: 33px;
}

.track_btn a {
	color: #fff;
}

.custom_btn,
.btn {
	background: #0D7836;
	padding: 7px 20px;
	color: #fff;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #0D7836;
	cursor: pointer;
	margin: 10px 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	/* min-width: 60px; */
	min-width: auto;
}

.back_btn,
.save_btn,
.custom_btn,
.btn,
.receipt_btn,
.edit_btn {
	font-family: "sfui-regular";
	font-weight: normal;
}

.modal-footer button {
	margin: 0;
	margin-right: 5px;
}

.custom_btn:hover,
.btn:hover {
	background: #10572B;
	color: #FFF;
}

.back_btn {
	padding: 7px 20px;
	cursor: pointer;
	width: auto !important;
	background: #636363;
	color: #f5f5f5;
	border-radius: 3px;
	border: 1px solid #636363;
	margin: 10px 5px 10px 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	/* min-width: 50px; */
}

.back_btn:hover {
	background: #686868;
	color: #fff;
}

.save_btn {
	background: #F96922;
	padding: 7px 20px;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #F96922;
	margin-right: 10px;
	margin: 10px 5px 10px 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	/* min-width: 60px; */
}

.save_btn:hover {
	background: #DF692F;
	color: #fff;
}

.edit_btn {
	background: #E8BA4E;
	padding: 7px 20px;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin-right: 10px;
	margin: 10px 5px 10px 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.edit_btn:hover {
	background: #F0B11C;
	color: #fff;
}

.receipt_btn {
	background: #ff8d00;
	padding: 7px 20px;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin-right: 10px;
	margin: 10px 5px 10px 0;
	border-color: #d77a07;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.receipt_btn:hover {
	background: #BD880B;
	color: #fff;
}

.main {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}

.main1 {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;
	padding-bottom: 30px;
}

:focus {
	outline: none;
}

#msform {
	position: relative;
	padding: 8px 15px;
}

.modal #msform {
	padding: 0;
}

.home h4 {
	padding-bottom: 15px;
	color: #0d7836;
}

.instruction_box ul li,
.upload_inst ul li {
	list-style-type: disc;
	text-align: left;
	margin-left: 16px;
	padding-top: 3px;
	line-height: 21px;

}

.form_main fieldset {
	background: white;
	border: 1px solid #cacbca;
	box-sizing: border-box;
	width: 100%;
	position: relative;
	text-align: left;
	padding: 0;
}

fieldset.plr10 {
	padding: 5px 15px 15px;
	margin-bottom: 10px;
}

.step_info {
	background: #ebebeb;
	padding: 3px 13px;
	border-bottom: 1px solid #cacbca;
	width: 100%;
}

#msform.change-request-form {
	padding: 0;
}

.request_table tr:first-child td,
.request_table tr td:first-child {
	background: #ebebeb;
	text-shadow: 0 0 BLACK;
}

.request_table tr:first-child td:first-child,
.request_table tr td:first-child {
	width: 150px;
}

.request_table .fa-times-circle {
	color: red;
}

.request_table .fa-check-circle {
	color: green;
}

.form_main {
	width: 100%;
}

.form-card {
	text-align: left
}

#progressbar {
	width: 100%;
	float: left;
	color: lightgrey;
	margin-bottom: 10px;
}

#progressbar .active {
	color: #515151;
	font-family: "sfui-semibold";
}

ul#progressbar p {
	line-height: 16px;
}

#progressbar li.sevenstages{
	width: 14.28% !important;
}

#progressbar li {
	list-style-type: none;
	font-size: 14px;
	width: 16.66%;
	float: left;
	position: relative;
	font-weight: 400;
	text-align: center;
}

#progressbar #passport-type:before {
	content: "1";
}

#progressbar #passport-type-tick p {
	font-family: "sfui-regular";
}

#progressbar #passport-type-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #image-upload:before {
	content: "2";
}

#progressbar #image-upload-tick p {
	font-family: "sfui-regular";
}

#progressbar #image-upload-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #personal:before {
	content: "3"
}

#progressbar #personal-tick p {
	font-family: "sfui-regular";
}

#progressbar #personal-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #contact:before {
	content: "4";
}

#progressbar #contact-tick p {
	font-family: "sfui-regular";
}

#progressbar #contact-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #kin:before {
	content: "5";
}

#progressbar #kin-tick p {
	font-family: "sfui-regular";
}

#progressbar #kin-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #documents-upload:before {
	content: "6";
}

#progressbar #documents-upload-tick p {
	font-family: "sfui-regular";
}

#progressbar #documents-upload-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #passport-delivery:before {
	content: "7";
}

#progressbar #passport-delivery-tick p {
	font-family: "sfui-regular";
}

#progressbar #passport-delivery-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar li:before {
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: block;
	font-size: 15px;
	background: #fff;
	border-radius: 50%;
	margin: 7px auto 2px auto;
	padding: 2px;
	border: 1px solid rgb(13 120 54 / 47%);
	font-family: "sfui-semibold";
	text-align: center;
}

#progressbar li:after {
	content: '';
	width: 100%;
	height: 5px;
	background: rgb(13 120 54 / 47%);
	position: absolute;
	left: 0;
	top: 25px;
	z-index: -1
}

#progressbar li.active::before {
	border: 1px solid #0d7836;
	background: #097734;
	color: #fff;
}

#progressbar li.active:after {
	background: #0d7836
}

label {
	width: 100%;
	text-align: left;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.medatory {
	color: #ee6969;
}

input,
select,
textarea {
	width: 100%;
	border: 1px solid #cacbca;
	height: 36px;
	padding: 0 10px;
	box-sizing: border-box;
	font-size: 14px;
	background: #fff;
}

.textbox {
	width: 100%;
	float: left;
	height: 150px;
}

.info {
	padding-bottom: 10px;
	width: 100%;
	float: left;
}

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-radius: 0;

}

.icon_caret {
	width: auto;
	float: right;
	position: absolute;
	right: 7px;
	top: 6px;
	color: rgba(0, 0, 0, .54);
}

.mat-select {
	height: 36px;
}

.input_box {
	position: relative;
	width: 100%;
	text-align: left;
}

.form_box .alert {
	font-size: 10px;
}

.instruction_box {
	border: 1px solid #cacbca;
	padding: 5px 14px 10px;
	text-align: justify;
	margin-top: 21px;
	letter-spacing: -0.2px;
}

.instruction_box h4 {
	font-size: 20px;
	color: #0d7836;
}

.fee span {
	width: 100%;
	float: left;
	text-align: center;
}

.focus_border:focus {
	border: 2px solid #4caf50;
	transition: 0.1s;
}

.focus_border {
	color: #212121 !important;
}

.effect {
	border: 1px solid #cacbca;
	padding: 7px 4px 9px;
	transition: 0.4s;
	color: #434343 !important;
}

.effect~.focus-border:before,
.effect~.focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 2px;
	background-color: #4caf50;
	transition: 0.4s;
}

.effect~.focus-border:after {
	top: auto;
	bottom: 0;
}

.effect~.focus-border i:before,
.effect~.focus-border i:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 2px;
	height: 0;
	background-color: #4caf50;
	transition: 0.6s;
}

.effect~.focus-border i:after {
	left: auto;
	right: 0;
}

.effect:focus~.focus-border:before,
.effect:focus~.focus-border:after {
	left: 0;
	width: 100%;
	transition: 0.4s;
}

.effect:focus~.focus-border i:before,
.effect:focus~.focus-border i:after {
	top: 0;
	height: 100%;
	transition: 0.6s;
}

footer {
	background: #ebebeb;
	width: 100%;
	float: left;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	border-top: 1px solid #d2d2d2;
}

.footer p {
	text-align: left;
}

.view-buttons {
	width: 100%;
	float: left;
	margin-bottom: 60px;
}

.img_upload {
	height: auto;
	width: auto;
	object-fit: cover;
	float: left;
	text-align: center;

}

.verify-picture {
	display: flex;
	padding: 0 0 5px 0;
	flex-wrap: wrap;
	max-width: 295px;
}

.verify-picture li {
	width: 90px;
	height: 90px;
	list-style: none;
	display: flex;
	margin: 0 5px 5px 0;
}

.verify-picture li img {
	object-fit: cover;
	max-width: 100%;
}

.verify-picture-grid .guidlines {
	display: flex;
}

.verify-picture-grid .guidlines p {
	padding: 0 0 0 5px;
}

.verify-picture-grid .guidlines i {
	padding: 3px 0 0 0;
	color: #f96922;
	font-size: 16px;
}

.pic-upload {
	display: flex;
	position: relative;
	border: 1px solid #cacbca;
	width: 100%;
	justify-content: center;
}

.icon-upload {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-upload i {
	cursor: default !important;
}

.icon-upload.verify {
	color: #1acb1a;
	background: #cbffdf;
	border: 1px solid #1acb1a;
}

.icon-upload.not-verify {
	color: #ffe2e2;
	background: #e53232;
	border: 1px solid #ffe2e2;
}

.img_box img {
	width: auto;
	border: 1px solid #cacbca;
	max-width: 100%;
	object-fit: contain;
	height: 260px;
}

#file-upload-button {
	position: absolute;
	opacity: 0;
	border: 0;
	left: 0;
	cursor: pointer !important;
	top: 0;
	width: 100px;
	height: 35px;
}

button.filt--upload {
	background: #d1ffd1;
	color: #0D7836;
	padding: 7px 10px;
	border-radius: 3px;
	border: 1px solid #c5d9c5;
	cursor: pointer !important;
	margin-top: 4px;
}

input#myFile {
	position: absolute;
	opacity: 0;
	border: 0;
	left: 0;
	cursor: pointer !important;
	top: 0;
	width: 100%;
	height: 35px;
	padding: 6px 8px;
}

.img_upload label {
	width: 100%;
	float: left;
	text-align: center;
}

span.icon_image {
	position: relative;
}

span.icon_image i {
	border: 1px solid #cacbca;
	border-radius: 5px;
	width: 30px;
	height: 27px;
	padding: 7px;
	float: left;
	position: absolute;
	right: 6px;
	background: #fff;
	top: -29px;
	text-align: center;
}

input.preview_btn {
	width: auto;
	padding: 6px 10px;
	height: auto;
	border: 1px solid #0d7836;
	border-radius: 3px;
	background: #0d7836;
	color: #fff;
	line-height: 14px;
	margin-right: 8px;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.new_app {
	position: relative;
	width: 100%;
	text-align: right;
	bottom: -4px;
	float: left;
}

.new_app .save_btn,
.new_app .custom_btn {
	margin: 0 3px !important;
	position: relative;
	z-index: 99;

}

.note-alert {
	border: 1px solid red;
	padding: 0 5px;
	margin: 0 0 15px;
}

.note-alert .alert {
	float: none;
	line-height: 18px;
	display: inline-block;
	font-size: 12px;
}

.alert {
	width: 100%;
	float: left;
	text-align: left;
	color: red;
	font-size: 10px;
	line-height: 100%;
	padding-top: 3px;
}

.comments {
	width: 100%;
	text-align: left;
	color: #ffbf00;
	text-shadow: 0 0 #ffbf00;
	font-size: 0.81em;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comments span {
	background: #ffbf00;
	border-radius: 50%;
	font-family: serif;
	font-weight: 700;
	color: #fff;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.comments p {
	width: calc(100% - 20px);
	line-height: 100%;
}

.re-upload-info_btm .img-required {
	padding-bottom: 10px;
}

.img-required {
	padding: 0 0 5px;
	position: relative;
}

.img-required .alert {
	position: absolute;
	bottom: 0;
}

.form_box {
	width: 100%;
	float: left;
	height: 70px;
	font-size: 15px;
}

.banner-img img {
	width: 100%;
}

.pt50 {
	padding-top: 50px;
}

.ptb20 {
	padding: 10px 0 15px;
}

.p20 {
	padding: 20px;
}

.pb10 {
	padding-bottom: 10px;
}

.personal.detail h3 {
	font-size: 14px;
	line-height: 19px;
	font-size-adjust: 0.6;
}

.ques_box {
	padding: 10px 0;
}

.ques_box input {
	width: auto;
	display: inline-block;
	height: auto;
}

.ques_box label {
	width: auto;
	padding-right: 11px;
}

.captcha-actions span.tooltip {
	top: 42px;
	bottom: inherit;
}

.captcha-actions span.tooltipArrow {
	border-bottom-color: hsla(0, 0%, 47.5%, .93);
	border-top-color: transparent;
	top: -12px;
	bottom: inherit;
}

span.tooltip {
	display: none;
	float: left;
	background-color: hsla(0, 0%, 47.5%, .93);
	color: #fff;
	padding: 3px 10px;
	border-radius: 3px;
	width: 280px;
	font-size: 12px;
	position: absolute;
	z-index: 999;
	bottom: 42px;
	left: 0;
	text-align: left;
}

.center_body h6 {
	text-align: left;
	font-size: 16px;
	padding: 10px 0;
}

span.tooltipArrow {
	content: " ";
	height: 0;
	position: absolute;
	width: 0;
	border: 7px solid transparent;
	border-top-color: hsla(0, 0%, 47.5%, .93);
	bottom: -12px;
	left: 12px;
}

table {
	width: 100%;
	border: 1px solid #cacbca;
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}

tbody {
	display: block;
	height: auto;
	overflow-y: auto;
	max-height: 360px;
}

thead {
	table-layout: fixed;
	display: table;
	width: 100%;
	position: sticky;
	top: 0;
}

tr {
	display: table;
	table-layout: fixed;
	width: 100%;
	float: left;
	border-bottom: 1px solid #cacbca;
}

th {
	padding: 1px 5px 0;
	color: rgb(10, 90, 30);
	background: #d7eaca;
	font-family: "sfui-semibold";
	height: 36px;
	text-align: left;
	font-weight: normal;
	table-layout: fixed;
	border-bottom: 1px solid #cacbca;
}

td {
	padding: 0 5px;
	height: 36px;
	text-align: left;
	border-left: 1px solid #ddd;
	text-overflow: ellipsis;
	overflow: hidden;
	table-layout: fixed;
}

table tr:last-child {
	border-bottom: 0;
}

.dash th,
.dash td {
	white-space: nowrap;
}

table input[type="checkbox"] {
	width: auto;
	height: auto;
	padding: 5px !important;
}

textarea {
	width: 100%;
	height: 106px;
	resize: none;
	font-family: "sfui-regular";
	font-size: 14px;
	float: left;
}

.textbox_profile {
	margin: 12px 0 55px;
	width: 100%;
	float: left;
}

.input_profile {
	margin: 12px 0 0;
	width: 100%;
	float: left;
}

.p-info fieldset {
	background: #f7f7f7;
	margin-bottom: 30px;
}

.p-info {
	width: 100%;
	float: left;
}

legend {
	padding: 0px 3px;
	color: #0d7836;
	font-family: sans-serif;
	font-size: 13px;
	text-align: left;
}

.t_and_c {
	position: relative;
	margin-top: 5px;
	display: flex;
	font-family: "sfui-regular";
	width: fit-content;
	float: none;
	padding: 0 0 6px;
}

.t_and_c .alert {
	position: absolute;
	left: 0;
	bottom: -3px;
}

.t_and_c input {
	width: 13px;
	height: 13px;
	margin-top: 3px;
}

.t_and_c label {
	font-family: "sfui-regular";
}

.cancel-btn {
	background: #ddd;
	border: 1px solid #ddd;
	color: #8a8787;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

i {
	cursor: pointer;
}

.cancel-btn:hover {
	background: #fff;
}


/* Login Page CSS Start */

.home {
	width: 100%;
	float: left;
}


.banner_text {
	width: 100%;
	float: left;
	color: #fff;
	position: absolute;
	top: 35%;
	left: 8%;
	line-height: 42px;
}

.banner_text hr {
	border: 1px solid #f96922;
	width: 40px;
	margin: 10px 0;
}

.top_head img {
	width: 38px;
	height: auto;
	display: inline-block;
}

.top_head {
	background: #F96922;
	padding: 5px 40px;
	text-align: left;
	margin-top: -66px;
	position: relative;
}

.main-bottom {
	background: #F5F5F5;
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
	height: 120px;
	position: relative;
	box-shadow: 0 14px 28px rgb(0 0 0 / 9%), 0 10px 10px rgb(0 0 0 / 9%);
}

.top_head span {
	width: auto;
	position: relative;
	display: inline-block;
	color: #fff;
	font-family: "sfui-semibold";
	left: 10px;
}

.top_head div {
	width: 100%;
	width: 100%;
	float: left;
	position: absolute;
	top: 28px;
	left: 26%;
	color: #fff;
	font-family: "sfui-semibold";
}

button {
	padding: 7px 20px;
	margin-top: 10px;
	background: #0D7836;
	border: none;
	position: relative;
	color: #fff;
	font-family: 'sfui-semibold';
	border-radius: 3px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	font-size: 14px;
}

button img {
	width: 18px;
	position: absolute;
	left: 16px;
	top: 8px;
}

.main-bottom p {
	text-align: justify;
	padding: 10px 20px 0;
}

.img_num img {
	float: right;
	position: absolute;
	right: 0;
	bottom: 0;
}

.loginBtn {
	cursor: pointer;
}

.dash.shop-cart i {
	cursor: default;
}

.form-card .note {
	color: red;
	text-shadow: 0 0 red;
	text-transform: uppercase;
	padding-bottom: 8px;
}

/* Login Page CSS End */

.disable {
	cursor: not-allowed;
	background: #ccc !important;
	border: 1px solid #ccc;
	color: #9d9d9d;
}

.dropdown-menu-top a:hover {
	color: #000 !important;
}

input:disabled,
select:disabled,
button:disabled {
	background-color: #e3e3e3 !important;
	cursor: not-allowed;
	border: 1px solid #cfcfcf;
	color: #8b8b8b !important;
}


/* ........ DASHBOARD ............  */

.bottom_span {
	width: 100%;
	float: left;
	border-bottom: 1px solid #f3f3f3;
	padding-bottom: 10px;
}

table.dash {
	margin-bottom: 0;
	height: 160px;
	margin-top: 20px;
}

.dash th {
	background: #0D7836;
	color: #fff;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 4;
	font-weight: normal;
}

tr.cus_row td {
	width: 50%;
	text-align: center;
	border: none;
}

.cus_row {
	margin: 20px 0;
}

.info-covid {
	background: #FBD0D0;
	color: #AC3939;
	padding: 6px 13px;
	width: 100%;
	font-size: 11px;
	margin-bottom: 16px;
	text-transform: capitalize;
}

.banner-img-name {
	width: 100%;
	background: #0d7836;
	color: #fff;
	text-align: left;
	padding: 4px 0;
	margin: 69px 0 7px;
}


.user_type,
.profile_type {
	text-align: center;
	margin: 0 auto;
	width: 50%;
}

.line_hr {
	width: 90%;
	margin: 0 auto;
	color: #CACBCA;
	height: 1px;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
}

.profile_type .custom_btn {
	margin: 10px 0;
}

.user_type ul li {
	display: block;
	position: relative;
	float: left;
	width: 100%;
}

.user_type ul li input[type=radio] {
	position: absolute;
	visibility: hidden;
}

.user_type ul li label {
	font-size: 14px;
	font-family: "sfui-regular";
	display: block;
	position: relative;
	padding: 14px 30px 5px;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
}

.user_type ul li .check {
	display: block;
	position: absolute;
	border: 1px solid #CACBCA;
	border-radius: 100%;
	height: 15px;
	width: 15px;
	top: 30px;
	left: 0;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.user_type ul li .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 7px;
	width: 7px;
	top: 3px;
	left: 3px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.user_type input[type=radio]:checked~.check {
	border: 1px solid #0D7836;
}

.user_type input[type=radio]:checked~.check::before {
	background: #0D7836;
}

.user_type input[type=radio]:checked~label {
	color: #0D7836;
}

.user_type span {
	width: 100%;
	float: left;
	font-size: 13px;
}

.tabset #tab1 {
	position: absolute;
	left: -100vw;
}

.tabset #tab2 {
	position: absolute;
	left: -150vw;
}

.tabset #tab3 {
	position: absolute;
	left: -90vw;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
}

.tabset>label {
	position: relative;
	display: inline-block;
	padding: 10px 15px;
	cursor: pointer;
	width: auto;
	float: none;
	font-size: 14px;
	color: #0000005d;
}

.tabset {
	width: 100%;
	float: left;
	position: relative;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: #0d7836;
}

.tabset>label:hover::after,
.tabset>input:focus+label::after,
.tabset>input:checked+label::after {
	background: #0d7836;
}

.tab-panel p {
	display: inline-block;
	padding-left: 20px;
}

.tabset>input:checked+label {
	border-color: #0d7836;
	border-bottom-color: rgb(13, 120, 54);
	border-bottom: 1px solid #0d7836;
	margin-bottom: 0;
	background: #0d7836;
	color: rgb(255, 255, 255);
}

.tab-panels {
	border-top: 1px solid #0d7836;
}

.tab-panel table {
	border-top: 0;
}

.step_info span {
	font-size: 16px;
	font-family: 'sfui-semibold';
}

.m40 {
	margin-bottom: 40px;
}

.w100 {
	width: 100%;
	float: left;
}

.search_grid {
	display: grid;
	grid-template-columns: calc(35% - 20px) 65%;
	width: 100%;
	gap: 20px;
}

.search_grid-upload-img {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.search_grid-upload-img .grid-box {
	width: 33.3%;
}

.profile_view ul {
	display: grid;
	align-items: center;
	grid-template-columns: 25% 25% 25% 24%;
	grid-gap: 8px;
	margin-bottom: 10px;
}

.profile_view li {
	padding-left: 45px;
}

.profile_view li:first-child {
	padding-left: 0;
}

.big_font {
	font-size: 20px;
}

.status_info img {
	width: 175px;
	text-align: right;
	float: right;
}

.grid_detail {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 49% 49%;
}

.grid_detail-box {
	padding: 0 10px;
}

.profile_view p {
	display: inline-block;
}

.fr {
	float: right;
}

.fr1 {
	float: right;
}

.grid_detail-box:first-child {
	border-right: 1px solid #9d9d9d;
}

.row_box {
	padding: 6px 0 0;
}

.total_box p {
	font-size: 19px;
}

.total_box label,
.total_box span {
	font-size: 15px;
}

.catagories_type {
	display: inline-block;
}

.catagories_type:last-child {
	padding-left: 50px;
}

.main-footer {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
}

fieldset {
	border: 1px solid #cacbca;
	margin: 10px 0 0;
}

.profile_view fieldset {
	padding: 0 20px 10px;
}

.notes {
	margin: 18px 0;
}

.payment_detail .grid-two-box {
	padding: 0;
}

.pd0 {
	padding: 0 !important;
}

.pd0 legend {
	margin: 0px 20px;
}

.pd12 {
	padding: 5px 20px 1px;
}

.modal-header {
	background: #0d7836;
	color: #fff;
	text-align: left;
	height: 45px;
}

.modal-header i {
	text-align: right;
}

.modal-header h4 {
	width: 100%;
	float: left;
	padding: 0;
	font-weight: normal;
}

.modal-content {
	height: auto;
	top: 17%;
	max-width: 800px !important;
}

.tl {
	text-align: left !important;
	margin-left: 22px;
}

.notice_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: left;
	padding: 0 !important;
	background: #0d7836;
	padding: 15px;
	border-radius: 6px;
	width: 100%;
	max-width: 35% !important;
	overflow: hidden;
}

.notice_modal .modal-body,
.notice_modal .modal-footer {
	background: #fff;
}

.notice_modal .modal-footer {
	justify-content: space-between;
}

.track_application {
	display: none;
}

/* payment page css  */

.grid-payment-box h4 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	border: 1px solid #f96922;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.pd20 {
	padding: 10px 10px;
}

.personal.detail span {
	width: 100%;
	float: left;
	font-size: 12px;
	line-height: 18px;
	padding-left: 16px;
	font-family: 'sfui-semibold';
}

.personal.detail {
	float: left;
	width: 75%;
}

.personal.detail i {
	float: left;
	width: 15px;
	padding-top: 2px;
	color: red;
}

.fee_detail {
	width: 25%;
	float: left;
	text-align: right;
}

.total_fee {
	position: absolute;
	bottom: 0;
	width: 100%;
	float: left;
	padding: 4px 10px;
	background: #0d7836;
	color: #fff;
}

.cart-info {
	width: 100%;
	float: left;
	position: relative;
	height: 370px;
	overflow-y: auto;
	box-shadow: 0 1px 4px #f4f4f4;
}

.grid-payment-box h5 {
	font-size: 17px;
}

.fee {

	border: 1px solid #f7f7f7;
	width: 100%;
	float: left;
	background: #f9f9f9;
	border: 1px solid #0d7836;
}

.fee div {
	width: 100%;
	float: left;
	display: block;
	text-align: left;
	border-bottom: 1px solid #eeebeb;
	padding: 6px 10px;
}

.pay_input input {
	padding: 3px 0;
}

.head_table {
	width: auto;
	float: left;
	text-align: left;
	font-size: 18px;
	margin-bottom: 10px;
}

.change_btn {
	margin: 0;
	float: right;
}

.fee span {
	width: auto;
	float: right;
	text-align: center;
	display: block;
}

.center_body {
	text-align: center;
	padding: 40px 10px;
	width: 100%;
	float: left;
	margin-bottom: 50px;
}

.card h1 {
	color: #0d7836;
	font-family: "sfui-semibold";
	font-size: 25px;
	margin-bottom: 10px;
}

.card p {
	color: #404F5E;
	font-size: 15px;
	margin: 20px 0;
}

.card i {
	color: #0d7836;
	font-size: 60px;
	line-height: 100px;
	margin-left: -10px;
}

.card {
	background: white;
	padding: 20px 30px;
	border-radius: 4px;
	box-shadow: 0px 2px 8px #C8D0D8;
	display: inline-block;
	margin: 0 auto;
	width: 800px;
}

.info_paid {
	border: 1px solid #ddd;
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.info_paid span {
	width: 100%;
	float: left;
	text-align: right;
	padding: 6px;
	border-bottom: 1px solid #ddd;
}

.info_paid span:last-child {
	border-bottom: 0;
}

.info_paid span b {
	text-align: right;
	width: auto;
	float: left;
}

.cardimg {
	position: absolute;
	width: 40px;
	top: 10px;
	right: 7px;
}

.modal {
	text-align: left !important;
	z-index: 9999 !important;
	background: rgba(0, 0, 0, .7) !important;
}

.modal table {
	margin: 0 !important;
	height: auto;
	width: auto !important;
}

.confirmbox-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 0 !important;
	background: #fff;
	padding: 15px;
	border-radius: 6px;
	min-width: 500px;
	overflow: hidden;
}

.confirmbox-container h3 {
	padding: 6px 10px 5px !important;
	margin: 10px 0 !important;
	border-bottom: 1px solid #0d7836 !important;
}

.confirmbox-container p {
	margin: 0;
	padding: 5px;
	overflow-wrap: break-word;
}

.confirmbox-container .modal-body p span {
	color: red;
}

.cod-popup {
	max-width: 1100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100vw - 100px);
	background: #fff;
	padding: 0;
	border-radius: 6px;
	text-align: left;
	overflow: hidden;
	max-height: calc(100vh - 100px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.guidelines-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100vw - 100px);
	background: #fff;
	padding: 0;
	border-radius: 6px;
	text-align: left;
	overflow: hidden;
}

.guidelines-container .modal-body {
	height: calc(100vh - 125px);
	overflow: hidden;
	overflow-y: auto;
}

.btn-grp button {
	margin: 0 7px 20px !important;
}

.card table i {
	font-size: 16px;
	line-height: normal;
}

.card table {
	height: auto;
}

.filter_box {
	width: 20%;
	float: left;
	margin-right: 10px;
}

.change_request {
	width: 100%;
	float: left;
	background: #f4f4f4;
	padding: 10px;
	margin-bottom: 20px;
}

.change_table {
	height: auto;
}

.change_request .save_btn {
	margin: 18px 0 0;
	width: 95px !important;
}

.detail_change_view {
	padding: 10px;
}

.detail_change_view table {
	margin-bottom: 0;
}

.field__input:focus::-webkit-input-placeholder,
.field__input:focus::-moz-placeholder {
	color: var(--uiFieldPlaceholderColor);
}

.field {
	border-width: 1px;
	display: inline-flex;
	position: relative;
	font-size: 1rem;
}

.field__input {
	height: 38px;
	padding: 0.25rem 1rem 0.15em;
	--uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);
}

.field__input::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input::-moz-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__input:focus::-moz-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__label-wrap {
	box-sizing: border-box;
	pointer-events: none;
	cursor: text;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.field__label-wrap::after {
	content: "";
	box-sizing: border-box;
	width: 100%;
	height: 0;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
}

.field__input:focus~.field__label-wrap::after {
	opacity: 1;
}

.field__label {
	position: absolute;
	left: var(--uiFieldPaddingLeft);
	top: calc(50% - .5em);
	padding: 0 1rem;
	line-height: 1;
	font-size: 14px;
}

.field__input:focus~.field__label-wrap .field__label,
.field__input:not(:placeholder-shown)~.field__label-wrap .field__label {
	font-size: 11px !important;
	top: var(--fieldHintTopHover, .25rem);
}

.dashbox {
	width: 100%;
	float: left;
}

.box_dashboard {
	border: 1px solid #0d7836;
	padding: 8px 10px;
	width: 100%;
	float: left;
	height: 100px;
}

.box_dashboard img {
	display: block;
	width: 60px;
	float: left;
}

.box_dashboard:hover {
	background: #f1fbeb;
	color: #0d7836;
}

.box_dashboard a {
	width: 100%;
	float: left;
}

.upload_icon {
	padding-top: 16px;
}

.dash_info {
	width: auto;
	padding: 5px 12px;
	text-align: left;
	margin-left: 10px;
	display: table;
}

.important_instructions {
	width: 100%;
	float: left;
	border-top: 1px solid #cacbca;
	margin: 30px 0;
}

.important_instructions li {
	list-style-type: disc;
	list-style-position: inside;
}

.important_instructions h3 {
	margin: 10px 0;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.phone_input {
	height: 36px;
	width: 100%;
	float: left;
}

#phone {
	height: 20px;
	border: 0 !important;
	padding-left: 88px !important;
}

.iti__selected-flag {
	width: 80px !important;
}

.grid-history {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 40% 60%;
	width: 100%;
	float: left;
}

.history_box {
	border: 1px solid #cacbca;
	width: 100%;
	float: left;
}

.history_box1 {
	border: 1px solid #cacbca;
	border-bottom: 0;
	width: 100%;
	float: left;
}

.green-band {
	background: #d7eaca;
	padding: 8px 8px 5px;
}

.history_box ul li {
	padding: 7px 10px;
	border-bottom: 1px solid #ddd;
}

.history_box ul li span {
	display: inline-block;
}

span.align-right {
	float: right;
}

.request_counts {
	margin-bottom: 18px;
}

.request_counts ul li {
	display: inline-block;
	width: 24%;
	border: 0;
	background: #fff;
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.09), 0 5px 6px rgba(0, 0, 0, 0.09);
	margin-right: 10px;
	padding: 15px 12px 10px;
}

.request_counts ul li:last-child {
	margin-right: 0;
}

.history_box1 table tr:last-child {
	border-bottom: 1px solid #cacbca !important;
}

.request_counts h4 {
	font-size: 18px;
	text-align: center;
}

.blue {
	background: #AFDFF5;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #A0B6E3;
	border-radius: 50%;
}

.lightgreen {
	background: #91E8B2;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #69CB8E;
	border-radius: 50%;
}

.lightorange {
	background: #EBD89B;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #E6C148;
	border-radius: 50%;
}

.re-red {
	background: #F88490;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #F88499;
	border-radius: 50%;
}

.history_box h4 {
	color: #0d7836;
}

.history_box1 table th {
	background: #f5f5f5;
	border-top: 1px solid #d5d5d5;
	border-bottom: 1px solid #d5d5d5;
	color: #353434;
	font-size: 12px;
}

.history_box1 table {
	width: 100%;
	border: 0;
	margin: 0;
}

.history_box .save_btn {
	float: right;
	text-align: right;
	width: 100%;
	margin: 13px 10px;
	height: 30px;
	padding: 4px 7px;
	border-radius: 4px;
}

.success {
	background: #60d23c;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 5px;
}

.danger {
	background: #e74c3c;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 5px;
}

.warning {
	background: #f1c40f;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 5px;
	text-align: center;
}

.footer_flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 0;
}

footer img {
	width: auto;
	height: auto;
}

.section_doc {
	background: #f1f1f1;
	padding: 10px 20px;
}


.image_upload_container {
	width: calc(100% - 20px);
	max-width: 1100px;
	padding: 0 !important;
	background: #fff;
	border-radius: 0;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.file-upload-modal {
	width: 800px !important;
}

.image_upload_container .modal-body {
	height: 580px;
	padding: 10px 10px 0;
	overflow: hidden;
}

.image_upload_container .centered {
	position: relative;
	margin: 0 auto;
	height: 100%;
	overflow-y: auto;
}

.image_upload_container .centered .grid-one-third,
.image_upload_container .file-upload {
	height: 100%;
}

.image_upload_container .form_box+.file-upload {
	height: calc(100% - 74px);
}

.image_upload_container .centered .grid-boxes {
	height: 100%;
}

.image_upload_container .centered .grid-boxes-upload {
	overflow-y: auto;
}

.image-cropper-box {
	height: calc(100% - 60px);
	border: 1px solid #cdcccc;
	overflow-y: auto;
}

.image-cropper-box img {
	max-width: 100%;
	display: flex;
}

.source-image {
	width: auto;
	height: auto;
}

.icon_dummy i {
	font-size: 70px;
	opacity: .4;
	padding: 20px;
}

input.readonly {
	background-color: #ebebeb;
}

.notification {
	border: 1px solid #cf0d0d;
	width: 100%;
	float: left;
	padding: 10px;
	margin-bottom: 20px;
}

.notice_board {
	display: flex;
	color: #d65050;
}

.notice_board i {
	font-size: 26px;
	color: #cf0d0d;
}

.message_board {
	padding-left: 13px;
}

textarea+.tooltip {
	top: 117px;
}

.fieldlabels.label_doc {
	line-height: 13px;
	height: 30px;
}

.confirmbox-container .modal-header,
.confirmbox-container .modal-body,
.confirmbox-container .modal-footer {
	text-align: left !important;
}

.profile-view {
	border-radius: 4px;
	width: 100%;
	background: #fff;
}

.s-head {
	border: 1px solid #979797;
	background: #fff;
	z-index: 999;
}

.s-head .result-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}

.s-head .result-header .post-img {
	width: 200px;
	height: 170px;
	overflow: hidden;
	object-fit: fill;
}

.s-head .result-header .post-img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.s-head .result-header .user-info-detail {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	width: calc(100% - 138px);
}

.s-head .result-header .user-info-detail p {
	width: 100%;
	float: left;
	text-align: justify;
}

.profile-view .s-content {
	border-radius: 0 0 4px 4px;
}

.profile-view .s-content ul {
	list-style-type: disc;
	margin: 0 32px 12px;
}

.profile-view .s-content .mb-12 {
	margin-bottom: 12px;
}

.profile-view .s-content .profile-table {
	width: 100%;
	text-align: left;
	line-height: 3em;
	border-collapse: collapse;
	margin-bottom: 24px;
	font-size: 14px;
	vertical-align: top;
}

.profile-view .s-content .profile-table thead {
	border-bottom: 1px solid #F8911C;
	text-align: left;
}

.profile-view .s-content .profile-table thead th {
	padding: 0 16px;
	height: 44px;
	font-weight: 600;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 9;
	background: #fff;
	-webkit-box-shadow: 0 1px 1px #ddd;
	box-shadow: 0 1px 1px #ddd;
}

.profile-view .s-content .profile-table tbody tr:nth-child(even) {
	background: #f6f6f6;
}

.profile-view .s-content .profile-table tbody tr td {
	color: #1c1d1f;
	height: 40px;
	padding-left: 16px;
}

.user_pic {
	border-right: 1px solid #cacbca;
	display: flex;
	align-items: center;
	position: relative;
}

.user_pic .check {
	position: absolute;
	background: #0d7836;
	padding: 3px 5px;
	bottom: 0;
	right: 0;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.user_pic img {
	width: 35mm;
	height: auto;
	object-fit: fill;
}

.sr-p-info {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto;
	grid-template-columns: 50% 50%;
	line-height: 10px;
}

.sr-p-info div {
	border-bottom: 1px solid #aeaeae;
	border-right: 1px solid #aeaeae;
	padding: 6px 10px;
}

.sr-p-info div:last-child {
	border-bottom: 0;
}

.sr-p-info div:nth-last-child(2) {
	border-bottom: 0 !important;
}

.sr-p-info div:nth-child(even) {
	border-right: 0 !important;
}

.header_logo {
	border-bottom: 1px solid #cacbca;
}

.header_logo span {
	position: relative;
	top: -20px;
	padding-left: 9px;
	font-size: 14px;
}

.p-body {
	font-size: 14px;
	font-family: "sfui-semibold";
	margin: 0;
}

.barcode img {
	width: 250px;
	height: 80px;
}

.fw-500 {
	font-weight: 600;
}

.line {
	border-bottom: 1px solid #979797;
	padding: 0px 10px;
	background: #f1f1f1;
}

.sr-p-info p {
	word-break: break-word;
	width: 100%;
	float: left;
	font-family: "sfui-regular";
	margin: 4px 0 0;
	line-height: 1.35;
	opacity: .88;
	text-overflow: ellipsis;
	overflow: hidden;
}

.total_box {
	font-size: 19px;
	font-family: "sfui-regular";
	padding-top: 5px;
}

.upper_bar,
.common_view {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
	min-height: 46px;
	flex-wrap: wrap;
}

.common_view_item {
	width: 20%;
}

.border_line {
	border: 0.5px solid #979797;
	margin-top: 10px;
}

.half_col {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.half_col_box {
	border: 1px solid #979797;
	float: left;
}

.tab50 div {
	border-bottom: 1px solid #d7d7d7;
	width: 100%;
	float: left;
}

.tab50 label {
	width: auto;
	padding: 4px 10px;
}

.tab50 p {
	color: #167816;
	padding: 2px 10px;
}

.tab50 span {
	padding: 2px 10px;
}

.form-card.photo-upload-check {
	padding: 0;
	height: calc(100% - 30px);
}

.fieldset-upload-check {
	height: calc(100vh - 210px);
}

.photo-upload-check #msform {
	height: 100%;
}

.photo-upload-check #msform .modal-body {
	height: calc(100% - 56px);
	overflow: hidden;
	overflow-y: auto;
}

.form-card {
	text-align: left;
	padding: 8px 15px;
}

.card_box_pass:hover {
	border: 1px solid green;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}

.grid-passport {
	display: grid;
	grid-template-columns: 66% 33%;
	grid-gap: 14px;
}

.passport_type input[type="radio"]+label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 22px;
	margin-right: 20px;
	font-size: 13px;
	width: auto;
	line-height: 11px;
}

.passport_type input[type="radio"]+label:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	margin-right: 14px;
	position: absolute;
	top: -3px;
	left: 0;
	border: 1px solid #aaa;
	background-color: #fff;
	border-radius: 50%;
}

.passport_type input[type="radio"] {
	display: none !important;
}

.passport_type input[type="radio"]:checked+label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	left: 3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #0d7836;
}

.passport_box {
	width: 100%;
	height: 46px;
	padding-top: 15px;
}

.tab50 div:nth-last-child(1) {
	border-bottom: 0px;
}

h4.seprate {
	font-size: 16px;
	color: #0d7836;
	padding: 15px 0 15px 0;
}

.seprate::after {
	content: "";
	border-bottom: 1px dotted #cfcfcf;
	width: 100%;
	float: left;
	padding: 2px 0;
}


/* Payment CSS */

.card_box {
	border-top: 1px solid rgba(3, 3, 3, 0.1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	margin-bottom: 10px;
	padding: 19px 20px;
}

.box_row {
	margin-top: 10px;
	width: 100%;
	float: left;
	border: 1px solid #cacbca;
}

.mt25 {
	margin-top: 25px;
}

.mt15 {
	margin-top: 15px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb30 {
	margin-bottom: 30px;
}

.mt13 {
	margin-top: 13px;
}

.mb10 {
	margin-bottom: 10px;
}
.mleft5 {
	margin-left: 5px !important;
}

.card_box img {
	width: 32px;
	margin-right: 10px;
	vertical-align: middle;
}

.h-1 {
	/* min-height: 232px; */
}

.card_box p {
	font-size: 14px;
	font-weight: normal;
	margin-top: 20px;
}

a {
	text-decoration: none;
	color: #000;
}

.box_row h4 {
	color: #0d7836;
}

.faq-heading {
	font-weight: 400;
	font-size: 18px;
	-webkit-transition: text-indent 0.2s;
	color: #333;
	position: relative;
	cursor: default;
}

.faq-text {
	font-weight: 400;
	color: #171717;
	width: 100%;
}

.faq-text-list {
    display: inline-flex;
	align-items: flex-start;
}

.faq-text-list input {
	position: relative;
	top: 4px;
}

.faq {
	width: 100%;
	margin: 0 auto;
	background: white;
	border-radius: 4px;
	position: relative;
	margin-top: 10px;
}

.faq-l {
	display: block;
	position: relative;
	/* overflow: hidden; */
	cursor: pointer;
	height: 56px;
	padding-top: 1px;
	margin-bottom: 10px;
	background-color: #FAFAFA;
	border: 1px solid #E1E1E1;
}

.faq input {
	width: auto;
	height: auto;
}

.faq-text label {
	width: auto;
	float: none;
	font-size: 14px;
	padding-left: 3px;
	cursor: pointer;
	vertical-align: text-bottom;
	padding: 0 10px 0 3px;
}

.gateway {
	display: none;
}

.faq .faq-arrow {
	width: 110px;
	text-align: right;
	padding: 5px 10px 5px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.faq img {
	width: 85px;
	height: 60px;
	object-fit: contain;
}

.faq-heading input {
	width: auto;
	height: auto;
	margin-right: 14px;
}

.faq input[type="checkbox"]:checked+label {
	display: flex;
	background: rgba(255, 255, 255, 255) !important;
	color: #4f7351;
	height: auto;
	transition: height 0.8s;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	width: 100%;
}

.payment-left {
	width: calc(100% - 110px);
	padding: 5px 10px;
}

.faq input[type='checkbox']:not(:checked)+label {
	display: block;
	transition: height 0.8s;
	height: 56px;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.passport_fees table,
.passport_fees table tr,
.passport_fees table td {
	border: 0;
	padding: 0;
}

.passport_fees table td:nth-child(1) {
	width: 35%;
}

.passport_fees table td:nth-child(2) {
	width: 65%;
}

.passport_fees {
	background: #f1f1f1;
	padding: 10px 20px;
}

.pay-info {
	display: inline-flex;
	font-size: 14px;
	font-family: 'sfui-semibold';
	width: 100%;
	flex-wrap: wrap;
}

/* HOME CSS */
.card_box_pass {
	border: 1px solid rgba(3, 3, 3, 0.1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	padding: 9px 10px;
	cursor: pointer;
	height: 100%;
}

.top_pass {
	display: flex;
	align-items: flex-start;
}

.mid-section h4 {
	font-size: 22px;
}

.rows_box {
	margin-top: 30px;
	width: 100%;
}

.grid_three {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	grid-gap: 15px;
}

.card_box_pass img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: 5px;
}

.card_box_item {
    width: calc(100% - 50px);
}

.body_section {
	padding-bottom: 80px;
}

.card_box_pass span {
	font-size: 14px;
	font-weight: normal;
	width: 100%;
	float: left;
}

/* RECEIPT CSS */
.pay-recipt table,
.pay-recipt table tr,
.pay-recipt table td {
	padding: 0 10px;
}

.pay-recipt table td:first-child {
	border-left: 0;
}

.pay-recipt table {
	margin-bottom: 8px;
}

.bank-notes h5,
.client-notes h5 {
	border: 0;
	border-bottom: 1px solid #aeaeae;
	background: #f1f1f1;
	padding: 4px 10px;
}

.pay-recipt h4 {
	background: #f1f1f1;
	padding: 4px 10px;
	border: 1px solid #aeaeae;
	border-bottom: 0 !important;
}

.pay-recipt table {
	border: 1px solid #aeaeae;
}

.pay-recipt {
	max-width: 650px;
	margin: 0 auto;
	margin-top: 10px;
	width: 100%;
}

.pay-recipt table th {
	background: #f1f1f1;
	color: inherit;
}

.bank-notes,
.client-notes {
	border: 1px solid #aeaeae;
	margin-bottom: 8px;
}

.bank-notes ul,
.client-notes ul {
	padding: 0 10px;
}

.pay-recipt h3 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.app_detail,
.pay_ack {
	padding: 0 12px;
}

.app_detail h4,
.pay_ack h4 {
	color: #0d7836;
}

.captcha {
	width: 100%;
	float: left;
}

.h_logo {
	display: none;
}

.h_logo .status_img {
	width: 75px;
}

.h_logo,
.h_logo td {
	border: 0;
}

.s-head .result-header .user-info-detail .barcode+.visa_type p {
	font-size: 25px;
	line-height: normal;
	text-align: right;
}

.s-head .result-header .user-info-detail .visa_type p {
	text-align: left;
	font-size: 25px;
	line-height: normal;
}

.barcode+.visa_type

/* ---   instructions box --*/
.tabordion {
	color: #333;
	display: block;
	margin: auto;
	position: relative;
	width: 100%;
}

.tabordion input[name="sections"] {
	left: -9999px;
	position: absolute;
	top: -9999px;
}

.tabordion section {
	display: block;
}

.tabordion section label {
	border-right: 2px solid #0d7836;
	cursor: pointer;
	display: block;
	font-size: 16px;
	padding: 21px 20px;
	position: relative;
	width: 180px;
	float: none;
	color: #bbb;
	z-index: 99;
}

.tabordion section article {
	display: none;
	left: 230px;
	min-width: 300px;
	padding: 0 0 0 21px;
	position: absolute;
	top: 0;
}

.tabordion section article:after {
	bottom: 0;
	content: "";
	display: block;
	left: -229px;
	position: absolute;
	top: 0;
	width: 220px;
	z-index: 1;
}

.tabordion input[name="sections"]:checked+label {
	background: #eee;
	color: #000;
}

.tabordion input[name="sections"]:checked~article {
	display: block;
}

/* # The Rotating Marker #*/
details summary::-webkit-details-marker {
	display: none;
}

details summary::before {
	content: "▶";
	position: absolute;
	top: 4px;
	left: 0.8rem;
	transform: rotate(0);
	transform-origin: center;
	transition: 0.2s transform ease;
}

details[open]>summary:before {
	transform: rotate(90deg);
	transition: 0.45s transform ease;
}

details {
	box-sizing: border-box;
	margin-bottom: 15px;
	background: #fff;
	display: none;
	width: 100%;
	float: left;
	position: relative;
}

details summary {
	outline: none;
	padding: 5px;
	display: block;
	background: #f96922;
	color: white;
	padding-left: 2.2rem;
	position: relative;
	cursor: pointer;
	z-index: 10;
	border-radius: 2px;
	text-align: left;
}

details .content {
	padding: 10px;
	border: 2px solid #888;
	border-top: none;
	position: absolute;
	z-index: 1005;
	background: #fff;
	width: 100%;
	text-align: left;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}

/* # The Sliding Summary # */
@keyframes details-show {
	from {
		margin-bottom: -80%;
		opacity: 0;
		transform: translateY(-100%);
	}
}

details>*:not(summary) {
	animation: details-show 500ms ease-in-out;
	position: relative;
	z-index: 1;
	transition: all 0.3s ease-in-out;
	color: transparent;
	overflow: hidden;
}

details[open]>*:not(summary) {
	color: inherit;
}

details .content ul {
	list-style-type: disc;
	margin-left: 10px;
}

/* COD CSS Start */

.mt30 {
	margin-top: 30px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mt5 {
	margin-top: 5px;
}

.mt10 {
	margin-top: 10px;
}

.mt20 {
	margin-top: 20px;
}

.mleft0 {
	margin-left: 0;
}

.title_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	margin-top: 20px;
}

.green_row {
	background: #0d7836;
	width: 100%;
	color: #fff;
	padding-left: 7.2%;
}

.cod-Data {
	width: 100%;
	float: left;
}

.box ol {
	list-style-position: outside;
	padding-left: 20px;
}

h3.heading-green {
	color: #0D7836;
}

.box {
	border-left: 0.5px dotted #d8d8d8;
	padding: 8px 10px;
	margin-top: 15px;
}

.box:first-child {
	border-left: 0;
}

.box h4 {
	margin-bottom: 8px;
}

.request-detail {
	display: flex;
	flex-wrap: wrap;
}

.tabs-container {
	position: relative;
	background: #F3F3F3;
	width: 320px;
	z-index: 20;
	border: 1px solid #d7d7d7;
}

.tabs-container label {
	position: relative;
	padding: 7px 10px 6px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: block;
	font-size: 13px;
	cursor: pointer;
	user-select: none;
}

.tabs-container label:hover {
	background: rgba(0, 0, 0, 0.2);
}

.tab-content {
	position: relative;
	background: #fff;
	width: 100%;
	box-sizing: border-box;
	z-index: 19;
	border: 1px solid #d7d7d7;
}

.tab-content:after {
	content: "";
	clear: both;
}

.tab-content .back_btn,
.tab-content .custom_btn {
	margin-top: 8px;
}

.tab-content h3 {
	background: #ebebeb;
	padding: 3px 15px;
	border-bottom: 1px solid #d7d7d7;
}

.tab-content .grid_two {
	padding: 10px 15px;
}

input[name="tab"] {
	display: none;
}

input[name="tab"]:checked+.tab-content {
	display: block;
	animation: slide 0.5s forwards;
}

.cod-Data .bk {
	background: #f1f1f1;
	padding: 25px 20px 20px;
	width: 100%;
}

.infoyn {
	width: auto;
	float: none;
	height: auto;
	padding: 0px 6px;
}

.info h3 {
	font-size: 20px;
}

.element:before {
	content: 'i';
	background: #f96922;
	border-radius: 50%;
	position: absolute;
	top: 12px;
	left: -18px;
	border: 3px solid #fff;
	font-family: serif;
	font-weight: 700;
	color: #fff;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.element {
	border-color: #f96922;
	position: relative;
	background-clip: content-box;
	background-color: inherit;
	border-left: 6px solid #f96922;
	margin-bottom: 23px;
	padding-left: 20px;
	margin-left: 10px;
}

.card_box_pass h4 a {
	display: flex;
	align-items: center;
}

.card_box_pass h4 a i {
	padding-left: 7px;
}

.half_w {
	width: 25%;
}

/* COD CSS End */
.blinkingText {
	font-size: 18px;
}

.highlight_txt {
	font-size: 16px;
	color: #f96922;
}

.h_txt {
	height: auto !important;
}

.common_view label,
.common_view p {
	font-size: 18px;
}

.cod-change-field th:first-child,
td:first-child {
	padding-left: 10px;
}

.iti {
	width: 100%;
}

.gateway-fee-note {
	color: red;
}

.book-appointment-note p {
	color: red;
	text-align: left;
}

/* newly added css 12/10 */
.max_height {
	height: calc(100vh - 230px);
}

.cus_width {
	width: 400px;
	height: 100%;
	float: left;
}

.personal_details_section table.request_table {
	width: 100%;
}

.personal_details_section table {
	width: 600px;
}

.personal_details_section table tr td:first-child {
	border-left: 0;
	width: 30%;
	font-size: 13px;
}

.personal_details_section table tr:nth-child(even) {
	background: #f2f2f2;
}

.cus_width p {
	width: 100%;
	float: left;
	text-align: right;
	font-size: 12px;
	font-weight: 600;
}

.resend {
	text-align: right;
}

p.resend a {
	color: #0d56a0;
	text-decoration: underline;
}

.message_cus {
	border: 1px solid #e9e7e7;
	width: auto;
	float: left;
	padding: 10px;
	border-left: 3px solid #f38249;
	background: #f4f2f2;
	border-radius: 2px;
}

.guidlines a {
	color: #188fd9;
	text-decoration: underline;
	font-family: 'sfui-semibold';
}

.upload_inst h5 {
	font-size: 16px;
}

.form_photo_box .cropped {
	border: 1px solid #d2cbcb;
	width: 200px;
	height: 180px;
	object-fit: cover;
}

.icons_uplaod {
	position: relative;
	cursor: pointer;
	text-align: center;
}

.icons_uplaod i {
	position: absolute;
	top: 21px;
	left: 15px;
	color: #707070;
	cursor: pointer;
}

input.default_btn {
	width: auto;
	min-width: 48px;
	margin: 10px 10px 0 0;
	background: #ececec;
	border-radius: 3px;
	cursor: pointer;
}

ul.steps_uplaod_pic {
	display: flex;
	border: 1px solid gainsboro;
	background: #fff;
	flex-wrap: wrap;
}

.steps_uplaod_pic li {
	text-align: center;
	margin: 0 auto;
	justify-items: center;
	justify-content: center;
	position: relative;
	align-items: center;
}

.steps_uplaod_pic li:last-child {
	border-right: 0;
}

.active_li {
	background-color: #c6f1c6 !important;
}

.sucess_li {
	background-color: #dff9df;
}

.form_photo_box label,
.img_box label {
	text-align: center;
}

.heading_title {
	position: relative;
}

.triangle-border {
	position: absolute;
	transform: rotate(45deg);
	top: calc(100% - 31px);
	left: calc(100% - 12px);
	width: 26px;
	height: 26px;
	border-top: 1px solid rgb(215 215 215);
	border-right: 1px solid rgb(215 215 215);
	background-color: #f7f3f3;
	z-index: 1002;
}

.title_li {
	padding: 0 5px;
	text-align: center;
}

.active_li .triangle-border {
	background-color: #c6f1c6;
}

.photo_icons {
	width: 100%;
	float: left;
	margin: 0 auto;
	display: flex;
	margin-bottom: 10px;
}

.form_photo_box p {
	line-height: 16px;
	text-align: left
}

.guidelines-container img {
	width: 800px;
	height: auto;
}

ul.guidelines_list {
	padding-left: 20px;
	padding-bottom: 15px;
	list-style: disc;
}

.wrapper {
	display: flex;
}

.tabs_vertical .wrapper {
	border: 1px solid #dbdbdb;
}

.tab {
	cursor: pointer;
	padding: 10px 10px;
	margin: 0px 2px;
	background: #0d7836;
	display: inline-block;
	color: #fff;
	border-bottom: 1px solid #178d44;
}

.tabs {
	width: 210px;
	box-shadow: 0 0.5rem 0.8rem #00000080;
	background: #0d7836;
}

.panels {
	background: rgb(235 235 235 / 96%);
	width: calc(100% - 210px);
	max-width: 100%;
	border-radius: 3px;
	overflow: hidden;
	padding: 20px;
	border: 1px solid #dbdbdb;
}

.panel {
	display: none;
	animation: fadein .8s;
	position: relative;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.panel-title {
	font-size: 20px;
	font-weight: bold;
	line-height: 19px;
	padding-bottom: 10px;
}

.radio_guide {
	display: none;
}

#one:checked~.panels #one-panel,
#two:checked~.panels #two-panel,
#three:checked~.panels #three-panel,
#four:checked~.panels #four-panel,
#five:checked~.panels #five-panel,
#six:checked~.panels #six-panel,
#seven:checked~.panels #seven-panel,
#eight:checked~.panels #eight-panel,
#nine:checked~.panels #nine-panel,
#ten:checked~.panels #ten-panel {
	display: block
}

#one:checked~.tabs #one-tab,
#two:checked~.tabs #two-tab,
#three:checked~.tabs #three-tab,
#four:checked~.tabs #four-tab,
#five:checked~.tabs #five-tab,
#six:checked~.tabs #six-tab,
#seven:checked~.tabs #seven-tab,
#eight:checked~.tabs #eight-tab,
#nine:checked~.tabs #nine-tab,
#ten:checked~.tabs #ten-tab {
	background: #fff;
	color: #0d7434;
}

.panel img {
	width: 200px;
	height: 200px;
	border: 1px solid #cbcbcb;
}

.heading_title {
	display: inline-block;
	width: 100%;
	padding: 5px 22px;
	position: relative;
	color: #a1a1a1;
	border-right: 1px solid #dfdfdf;
	background-color: #f7f3f3;
	border: 1px solid gainsboro;
	margin-bottom: 10px;
}

.photo_grid {
	display: grid;
	grid-template-columns: 46% 51%;
	grid-column-gap: 46px;
}

.heading_title .active_li .title_li {
	color: #00743d;
	font-weight: 600;
}

.pic_guide h4 {
	line-height: 18px;
	padding-bottom: 4px;
}

.pic_guide li {
	list-style: decimal;
	margin-left: 15px;
	line-height: 24px;
}

.pic_guide p {
	line-height: 18px;
}

.upload_btn {
	background: #d1ffd1;
	border: 1px solid #c5d9c5;
	color: green;
	border-radius: 3px;
	position: relative;
	min-width: 50px;
	width: auto;
	display: inline-block;
	padding: 6px 8px;
	text-align: center;
	position: relative;
	z-index: 99;
	height: 35px;
	cursor: pointer;
}

label.fileUpload input {
	display: none;
}

.history_btn {
	background: #f96922;
	border: 1px solid #e3743d;
	color: #fff;
	border-radius: 3px;
	position: relative;
	min-width: 50px;
	width: auto;
	display: inline-block;
	padding: 6px 8px;
	text-align: center;
	position: relative;
	z-index: 99;
	width: 100px;
	height: 35px;
}

.capture_btn {
	background: #ffed97;
	color: #706a0f;
	border: 1px solid #cfcf24;
	margin-left: 5px;
	border-radius: 3px;
	font-family: 'sfui-semibold';
}

.wid40 {
	width: 40%;
}

.wid20 {
	width: 20%;
}

.validate_btn {
	background: #eb9b22;
	color: #fff;
	position: relative;
	font-family: 'sfui-semibold';
	min-width: 60px;
	width: auto;
	border-radius: 3px;
}

.photo_box {
	border: 1px dotted #999;
	height: 375px;
	width: 375px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.croppie-container {
	width: 100%;
	height: auto !important;
}

.instructionss {
	text-align: left;
}

.grid-photo-two {
	display: grid;
	grid-template-columns: 375px calc(100% - 395px);
	text-align: left;
	gap: 18px;
}

.issue_box {
	border: 1px solid #ebe9e9;
	background: #f1f1f1;
	padding: 10px 20px;
	text-align: left;
	overflow-y: auto;
}

.issue_box ul {
	padding-left: 20px;
}

.issue_box ul li {
	list-style: disc;
}

.center-content {
	justify-content: center;
}

.croppie-container .cr-slider-wrap {
	width: 75%;
	margin: 5px auto 0 !important;
}

ngx-super-croppie {
	position: relative;
	float: left;
}

.icons_uplaod_1 {
	margin-top: 10px;
}

.upload_boxx {
	position: relative;
	width: 100%;
	float: left;
	margin: 0 auto;
}

.webcam-wrapper video {
	width: 100%;
	float: left;
	object-fit: cover;
	border: 1px solid #d2d0d0;
}

.snapshot img {
	height: 400px;
	object-fit: cover;
	width: 100%;
	border: 1px solid #d2d0d0;
}

.support_document table th {
	background: none;
}

.support_document table tr td {
	border-left: 0;
	vertical-align: text-top;
	padding-top: 2px;
	padding-bottom: 15px;
	position: relative;
}

.support_document table tr td .alert {
	position: absolute;
	bottom: 3px;
	left: 10px;
}

.support_document table tr td.actionable {
	text-align: right;
}

.actionable {
	text-align: right;
}

.actionable span {
	padding: 1px 5px;
	border-radius: 3px;
	margin-right: 3px;
	font-size: 12px;
	cursor: pointer;
}

.actionable span:last-child {
	margin-right: 0;
}

.actionable .upload {
	background: #a9f3a9;
	border: 1px solid #b3cdb3;
	color: green;
}

.actionable .preview {
	background: #baecff;
	color: #125a87;
	border: 1px solid #5cb1cb;
}

.actionable .delete {
	background: #fdcfcf;
	color: #e12121;
	border: 1px solid #fba2a2;
}

.actionable .check i {
	color: green;
	font-size: 14px;
	cursor: default;
}

.grid-guideline {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 10px);
	gap: 20px;
}

.wid400 {
	width: 500px;
	float: left;
}

.file-upload .photo_box {
	max-width: 500px;
	width: 100%;
	height: 300px;
}

.files_btn {
	text-align: left !important;
}

pdf-viewer {
	width: 100%;
	float: left;
	z-index: 1002;
	position: relative;
	border: 1px solid #cdcccc;
}

.pdf-viewer-with-option {
	height: 438px;
}

.pdf-viewer-without-option {
	height: 510px;
}

.view_pdf pdf-viewer {
	height: calc(100% - 10px);
}

.history_info_prv pdf-viewer {
	height: 100%;
}

.margin60 {
	margin-top: 60px;
}

p.guidlines {
	line-height: 17px;
}

.file-upload .croppie-container {
	width: auto;
	float: left;
	text-align: left;
}

.file-upload .croppie-container .cr-slider-wrap {
	margin: 7px 0 0 !important;
}

.details_photo li {
	list-style: disc;
	margin: 0 0 5px 17px;
	text-align: left;
}

.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
	overflow: scroll;
}

.form_height {
	height: 74px;
}

.tick_mark {
	position: absolute;
	left: -15px;
	top: -15px;
}

.tick_mark .fa-check {
	color: #1acb1a;
	background: #cbffdf;
	border-radius: 50%;
	font-size: 18px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #1acb1a;
}

.tick_mark .fa-times {
	color: #ffe2e2;
	background: #e53232;
	border-radius: 50%;
	font-size: 18px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ffe2e2;
}

.grid-guideline table tbody {
	max-height: calc(100vh - 200px);
	background: #fbfbfb;
}

.grid-guideline table tbody tr:nth-child(even) {
	background-color: #efefef;
}

.grid-guideline table tbody td {
	height: auto;
	text-align: left;
	padding: 5px 5px;
	float: left;
}

.grid-guideline table tbody td:first-child {
	width: 160px;
	font-weight: 600;
	vertical-align: top;
}

.grid-guideline table tbody td:last-child {
	width: calc(100% - 160px);
}

.view_pdf p {
	background: #e7e7e7;
	border: 1px solid #d9d9d9;
	text-align: left;
	margin-bottom: 10px;
	padding: 3px 10px;
}

.pro-table-grid {
	margin-top: 5px;
}

.profile_table {
	border: 0 !important;
	padding: 0;
	margin: 0;
	margin-top: 10px;
	vertical-align: text-top;
}

.profile_table tr {
	border: 0 !important;
}

.profile_table tr td:first-child,
.profile_table tr td {
	padding-left: 0 !important;
	border-left: 0 !important;
	vertical-align: top;
	padding-right: 0;
	height: auto;
}

.documentt_view tr td {
	border-bottom: 1px solid #d7d7d7;
}

.wid50 {
	width: 50%;
	float: left;
}

.book_center {
	color: #DF692F;
	font-size: 12px;
	margin-top: -2px;
	width: 100%;
	float: left;
}

.txt-indent {
	text-indent: 16px;
	margin-top: -3px;
}

.re-upload-wrapper .photo_icons {
	float: none;
	justify-content: flex-start;
	padding: 5px 0 0;
	margin-bottom: 0;
}

.img_re-upload-info label input {
	cursor: pointer;
}

.img_re-upload .img_upload {
	float: none;
	display: flex;
	border: 1px solid #cacbca;
	margin-bottom: 10px;
}

.img_re-upload-info {
	width: calc(100% - 25mm);
	padding: 10px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
}

.img_re-upload .user_pic img {
	width: 25mm;
}

.re-upload-info_top {
	display: flex;
	justify-content: space-between;
	text-align: left;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
	padding-bottom: 10px;
}

.re_upload_box {
	width: 25%;
	padding: 0 10px 0 0;
}

.re-upload-info_btm .txt-indent {
	text-indent: 0;
}

.t_and_c.reupload-confirm {
	padding: 0 0 10px 0;
	height: auto;
	position: relative;
}

.reupload-confirm .alert {
	position: absolute;
	bottom: 0;
	left: 0;
}

.support_document table.re-upload-table tr td {
	height: auto;
}

.applicant_details p,
.applicant_details span {
	padding-bottom: 2px;
	display: inline-block;
}

.applicant_details .comments span {
	display: flex;
}

.applicant_details .comments {
	display: flex;
}

.applicant_details {
	padding: 10px 10px 20px;
	position: relative;
}

.applicant_details .alert {
	position: absolute;
	bottom: -1px;
	left: 30px;
}

.img_re-upload-info label {
	text-align: left;
	font-size: 16px;
	line-height: 100%;
}

.img_re-upload-info p {
	font-size: 13px;
}

.history_modal {
	display: flex;
	justify-content: space-between;
	height: 100%;
	padding: 0 0 10px;
}

.history_modal_info {
	width: 40%;
	min-width: 420px;
	padding: 0 10px 0 0;
}

.cod-change-data {
	margin-bottom: 0;
	width: 100%;
	border: none;
}

.cod-change-data th {
	background: #f1f1f1;
	color: rgb(67, 67, 67);
	border-color: #979797;
	height: auto;
}

.cod-change-data tr td:first-child {
	border-left: none;
	font-family: "sfui-semibold";
}

.cod-change-data tr,
.cod-change-head {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	border-color: #979797;
}

.cod-change-data td {
	border-color: #979797;
	height: auto;
	padding-left: 10px;
	text-align: left;
	border-left: 1px solid #979797;
	line-height: 20px;
}

.history_info-inner td {
	border-bottom: 1px solid #ddd;
}

.history_info-inner td:first-child {
	border-bottom: none;
}

.history_info-inner tr:last-child td {
	border-bottom: none;
}

.history_info_prv {
	width: calc(60% - 10px);
	height: 100%;
	border: 1px solid #cacbca;
}

.doc-type+.doc-data {
	height: calc(100% - 60px);
}

.doc-data {
	height: calc(100% - 10px);
	border: 1px solid #d9d9d9;
	overflow-y: auto;
}

.doc-data img {
	max-width: 100%;
	object-fit: contain;
	display: flex;
}

.barcode {
	display: flex;
	padding: 1px 0;
}

.s-head .barcode+.visa_type {
	width: calc(100% - 125px);
}

.mat-select-panel-wrap {
	position: relative;
	top: 26px;
}

.mat-select-panel-wrap .mat-select-panel {
	min-width: calc(100% + 10px) !important;
	transform: translateX(11px) !important
}

.banner-img-name h2 {
	font-size: 16px;
	font-weight: normal;
	padding: 0;
}

.highlight_row {
	background-color: #f1f8ed;
}

.pay-info p {
	width: auto;
	position: relative;
}

.pay-info p:first-child:before {
    content: '|';
    position: absolute;
    right: 0;
    top: 0;
}

.pay-info p:first-child {
	padding-right: 20px;
}

.pay-info p:last-child {
	padding-left: 20px;
}


.captcha-actions input {
	width: auto;
}

.captcha-container {
	width: 100%;
}

.image_upload_radio {
	width: auto !important;
}

.image_upload_radio.radio-btn {
	height: auto;
}

.radio-grid .radio-span {
	width: fit-content;
}

.radio-grid {
	padding: 0 0 5px;
}

.image_upload_radio_container {
	gap: 1rem;
}

.radio-span {
	gap: 5px;
	align-items: center;
}

.radio-span input[type="radio"], .radio-span label { 
	cursor: pointer;
}

.image_upload_exception {
	color: #808080; 
	font-size: 10px; 
	font-family: 'sfui-semibold'; 
	cursor: default; 
	position: relative; 
	bottom: 8px;
	display: block;
}

.radio-grid-wrap {
    padding-bottom: 10px;
    position: relative;
}

.radio-grid-wrap .alert {
    position: absolute;
    bottom: 0;
    left: 0;
}

@media (max-width: 1365px) {
	.book_center {
		font-size: 10px;
	}
}

@media (min-width: 992px) and (max-width: 1330px) {
	.search_grid {
		grid-template-columns: calc(40% - 3px) 60%;
	}
}

@media (min-width: 768px) and (max-width: 1340px) {
	.support_document table tr td {
		padding-bottom: 20px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1450px) {
	.form_photo_box p {
		font-size: 12px;
	}

	.pic_guide li,
	.pic_guide p {
		font-size: 14px;
		padding-top: 2px;
	}

	.heading_title {
		padding: 5px 10px;
	}

	.title_li {
		font-size: 12px;
		padding: 0 13px;
	}

	.form_photo_box .cropped {
		width: 182px;
	}

	.image_upload_container .modal-body {
		/* height: calc(100vh - 125px); */
		overflow-y: auto;
	}

	/* pdf-viewer{height:calc(100vh - 272px)} */
	.icons_uplaod_1 {
		margin-top: 6px;
	}

	.files_btn .upl {
		top: 16px;
	}

	.view_pdf pdf-viewer {
		height: 450px;
	}

	.pdf-viewer-without-option {
		height: calc(100vh - 220px) !important;
	}

	.pdf-viewer-with-option {
		height: 330px;
	}

	.cr-slider {
		height: 28px;
	}

	.tab {
		padding: 6px 4px;
		margin: 0;
	}

	.panel img {
		width: 140px;
		height: auto;
	}

	.tick_mark .fa-check,
	.tick_mark .fa-times {
		font-size: 17px;
		padding: 4px 6px;
	}
}

@media (max-width: 1199px) {

	.main,
	.main-footer {
		width: 100%;
	}

	.profile-view-main {
		width: 100% !important;
	}

	.img_re-upload-info label {
		font-size: 14px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.main,
	.main-footer {
		padding-right: 30px;
		padding-left: 30px;
	}

	.common_view label,
	.common_view p {
		font-size: 15px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.s-head .result-header .user-info-detail .visa_type p {
		font-size: 20px;
	}

	.upper_bar,
	.common_view {
		padding: 0 10px;
	}

	.common_view {
		justify-content: normal;
	}

	.top_head {
		padding: 5px 20px;
	}

	.nav-links {
		display: block;
		list-style: none;
		width: auto;
		padding: 0;
		text-transform: capitalize;
	}

	.instruction_box {
		padding: 15px;
		font-size: 12px;
	}

	.main1 {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}

	.faq {
		padding-left: 20px;
	}

	.box_row h4 {
		padding-left: 20px;
	}

	.passport_fees h4 {
		padding: 10px 0;
	}

	.grid-two,
	.grid-payment {
		grid-template-columns: 100%;
	}

	.cart-info {
		height: auto;
	}

	.grid-two.tab_screen {
		grid-template-columns: 49% 49%;
	}

	.notice_modal {
		max-width: 85% !important;
	}

	.grid_three {
		grid-template-columns: 49% 49%;
		grid-gap: 15px;
	}

	.confirmbox-container {
		width: 85% !important;
	}

	.cod-Data .bk {
		padding: 10px 10px 0;
	}

	.tabs-container {
		width: 180px;
		height: 575px
	}

	.tab-content .grid-two {
		grid-template-columns: 49% 49% !important;
	}

	.body_section {
		padding-bottom: 65px;
	}
}

@media only screen and (max-width: 991px) {

	body .modal-footer,
	body .modal-header {
		padding: 0 10px;
	}

	.photo_box {
		width: auto;
	}

	td.upload-support-doc {
		width: 70%;
	}

	td.upload-support-doc-btn {
		width: 30%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.grid-guideline {
		grid-template-columns: 100%;
		gap: 0;
	}

	.grid-guideline .grid-boxs {
		padding-top: 20px;
	}

	.common_view label,
	.common_view p {
		font-size: 15px;
	}

	.grid-one-third {
		grid-template-columns: 59% 39%;
	}

	.main,
	.main-footer {
		padding-right: 20px;
		padding-left: 20px;
	}

	.re_upload_box {
		width: 50%;
		padding: 0 0 5px;
	}

	.search_grid {
		grid-template-columns: calc(52% - 3px) 48%;
		gap: 20px;
	}
}

@media (max-width: 767px) {

	body,
	body p,
	p,
	.p-body {
		font-size: 13px;
	}

	.body_section {
		padding-bottom: 50px;
	}

	.step_info span {
		font-size: 14px;
	}
	button.filt--upload {
		padding: 5px 10px;
	}

	.s-head .visa_type {
		width: 100%;
	}

	.home_grid .col_box {
		margin-bottom: 5px;
	}

	.history_modal_info {
		width: 100%;
		min-width: 100%;
		padding: 0;
	}

	.search_grid-upload-img .grid-box {
		width: 100%;
	}

	.t_and_c {
		margin-top: 0;
		font-size: 12px;
	}

	.s-head .result-header .user-info-detail .visa_type p {
		/* text-align: center; */
		Font-size: 15px;
	}

	.s-head .result-header .user-info-detail .barcode+.visa_type p {
		font-size: 15px;
	}

	table.profile_table td {
		text-align: left;
	}

	span.tooltip {
		width: auto;
		max-width: 300px;
		font-size: 10px;
	}

	table.profile_table td .profile_table td {
		float: none;
		display: table-cell;
	}

	table.profile_table td .profile_table:last-child td {
		border-bottom: none;
		padding-top: 0;
	}

	table.profile_table td .profile_table td:last-child span {
		padding-right: 0;
	}

	table.profile_table td .profile_table td label {
		padding: 0;
	}

	.profile-view-main {
		width: 100% !important;
	}

	.main,
	.main-footer {
		padding-right: 10px;
		padding-left: 10px;
	}

	.blinkingText {
		font-size: 14px;
	}

	hr.header_line {
		height: 40px;
	}

	.header_box span {
		font-size: 12px;
	}

	.nav-links li {
		font-size: 13px;
	}

	.mid-section h4 {
		font-size: 20px;
	}

	.re-upload-info_top {
		padding: 0 0 10px;
		align-items: center;
	}

	.re_upload_box {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.img_re-upload-info label {
		width: 140px;
		display: inline-block;
	}

	.re_upload_box p {
		width: calc(100% - 140px);
	}

	.grid-one-third {
		grid-template-columns: 100%;
		grid-gap: 0;
	}

	.none_dis {
		display: none;
	}

	details {
		display: block;
	}

	.support_document-details {
		margin-bottom: 5px;
	}

	.support_document table.re-upload-table tr td {
		height: auto;
		text-align: left;
	}

	.form-card.photo-upload-check,
	.fieldset-upload-check,
	.photo-upload-check #msform,
	.photo-upload-check #msform .modal-body {
		height: auto;
	}

	.pay_ment thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.support_document table tr td {
		text-align: left;
		border-bottom: none;
		height: auto;
		padding: 2px 0 2px;
	}

	.support_document table tr td .alert {
		left: 0;
		bottom: 2px;
	}

	.support_document table tr td:last-child {
		padding-bottom: 8px;
	}

	.file-upload .photo_box {
		width: auto;
		height: 300px;
	}

	.pay_ment td,
	.pay_ment tr {
		width: 100%;
		float: left;
	}

	.pay_ment tr {
		display: block;
	}

	.pay_ment td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: 13px;
		text-align: right;
		padding: 5px;
	}

	.pay_ment td::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		font-family: "sfui-regular";
		/* text-transform: uppercase; */
	}

	.pay_ment td:last-child {
		border-bottom: 0;
	}

	.top_head {
		margin-top: 0
	}

	.dropdown a.custom_btn {
		position: relative;
		display: block;
		margin: 0;
	}

	.fee {
		margin-bottom: 20px;
	}

	.half_col {
		display: grid;
		grid-gap: 9px;
	}

	.line {
		padding: 0px 4px;
	}

	#progressbar li {
		font-size: 10px;
	}

	.new_app {
		bottom: 0;
	}

	.tabset {
		top: 20px;
	}

	.tabset>label {
		padding: 2px 7px;
		width: 33%;
		font-size: 12px;
	}

	.head_table {
		background: #00743d;
		color: #fff;
		padding: 0 13px;
	}

	fieldset {
		position: relative;
	}

	.dropdown-menu-top {
		padding-bottom: 0;
	}

	.grid-personal,
	.grid-two,
	.grid-container,
	.grid-payment,
	.search_grid,
	.grid-photo-two,
	.guidelines-container,
	.grid-guideline {
		grid-template-columns: 100%;
		gap: 0;
	}

	.clearfix {
		display: none;
	}

	.instruction_box {
		display: none;
	}

	.grid-container {
		grid-gap: 0;
	}

	.banner_text {
		top: 6%;
		left: 5%;
	}

	.banner-img_home {
		height: 180px;
	}

	.instruction_box {
		margin-top: 2px;
		padding: 1px 10px 5px;
	}

	hr.header_line {
		margin: 0;
	}

	.main-bottom {
		margin-bottom: 10px;
	}

	.login_nav {
		display: flex !important;
		float: none;
	}

	.custom_btn,
	.btn {
		margin: 10px 0;
	}

	.back_btn, .edit_btn, .save_btn, .custom_btn, .btn {
		padding: 7px 7px;
	}



	table {
		height: auto;
		border: 0;
	}

	.grid-two-box {
		margin-bottom: 0;
	}

	.grid-two,
	.grid-personal {
		grid-gap: 0;
		position: relative;
	}

	.view-buttons {
		margin-bottom: 30px;
	}

	.cus_row {
		margin: 0;
		width: 100%;
		float: left;
	}

	.cus_row input {
		margin: 0 !important
	}

	.faq-text span {
		width: 100%;
		float: left;
	}

	.faq-text span.faq-text-list {
		width: auto;
	}

	.pay-recipt {
		width: 100%;
	}

	.user_type ul li label {
		padding: 17px 20px 5px;
	}

	.user_type ul li .check {
		top: 20px;
		left: 0;
	}

	.shop-cart .custom_btn {
		padding: 7px 15px !important;
	}

	.main1 {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
		height: auto;
	}

	.grid-box {
		text-align: left;
	}

	.pay_input input {
		font-size: 12px;
		padding-top: 4px;
	}


	.fix_height {
		height: auto;
	}

	.cart-info {
		height: 67px;
		overflow-y: auto;
		margin-bottom: 15px;
	}

	.dropdown-menu-top li a {
		color: #000;
	}

	.card {
		width: 100%;
		padding: 20px 15px;
	}

	.confirmbox-container,
	.image_upload_container {
		width: 95% !important;
	}

	.modal-content {
		height: auto;
	}

	.profile_view li {
		padding-left: 0;
	}

	.profile_view ul {
		grid-template-columns: 100%;
	}

	.fr1 {
		float: none;
	}

	.grid_detail {
		grid-template-columns: 100%;
		grid-gap: 0;
	}

	.grid_detail-box {
		padding: 0;
	}

	.grid_detail-box:first-child {
		border-right: 0;
	}

	.grid-modal-container {
		grid-template-columns: 37% 36% 18%;
		grid-gap: 10px;
	}

	.user_type,
	.profile_type {
		width: 100%;
		margin-bottom: 90px;
	}

	.grid-modal-box .btn {
		margin: 20px 0 !important;
	}

	.profile_type .form_box {
		height: 75px;
	}

	.profile_type .custom_btn {
		margin: 10px 5px;
	}

	fieldset.plr10 {
		padding: 0px 8px;
	}

	#msform {
		padding: 8px 7px;
	}

	.card_box_pass {

		padding: 9px 4px;
	}

	.card_box_pass a {
		font-size: 13px;
	}

	.radio label {
		margin-bottom: 15px;
	}

	/* .mt15 {
		margin-top: 0;
	} */

	.sr-p-info p {
		margin: 5px 0 0;
		line-height: 17px;
	}

	.tab50 div {
		padding: 2px 6px;
	}

	.sr-p-info label {
		line-height: 14px;
	}

	.half_col_box {
		width: 100%;
	}

	.modal-body {
		padding: 10px;
	}

	.notice_modal {
		max-width: 95% !important;
	}

	.notice_modal .modal-body {
		padding: 6px;
	}

	.faq {
		margin: 10px;
	}

	table.pay_ment {
		border: 1px solid #dfdfdf;
	}

	footer img {
		width: 70px;
		height: 39px;
	}

	.h_txt {
		height: 135px !important;
	}

	.sr-p-info div {
		padding: 5px 5px;
	}

	.box_row h4 {
		padding-left: 0px;
	}

	.box_row h4.payment-h4 {
		padding-left: 10px;
		padding-bottom: 0;
	}

	.main-footer {
		padding: 0 10px;
		width: 100%;
	}

	.footer_flex p {
		font-size: 0.83em;
	}

	.tabordion section {
		display: inline-block;
	}

	.tabordion section label {
		font-size: 14px;
		width: 90px;
		padding: 5px 15px;
	}

	.tabordion section article {
		left: 0;
		min-width: auto;
		top: auto;
	}

	.form_box {
		height: 73px;
	}

	.history_info-inner table th {
		position: relative;
		height: auto;
		padding: 0 5px 0;
	}

	.history_info-inner table td {
		display: table-cell;
		text-align: left;
		height: auto;
		min-height: 32px;
	}

	.history_info-inner table {
		border: 1px solid #cacbca;
	}

	.history_info-inner table td:nth-child(2) {
		border-left: 1px solid #ddd;
	}

	table tr {
		border-bottom: 1px solid #0d7836;
		display: table
	}

	table td,
	table tr {
		float: left
	}

	table td {
		display: block;
		text-align: right;
		padding: 5px 12px;
		border-left: 0;
	}

	table td:before {
		content: attr(data-label);
		float: left;
		font-family: sfui-semibold;
		text-transform: capitalize
	}

	.passport_fees table {
		margin-bottom: 0;
	}

	.passport_fees table tr td {
		border-bottom: 0 !important;
		/* font-size: 0.85em !important; */
        line-height: 150%;
        height: auto;
	}

	.upper_bar,
	.common_view {
		justify-content: flex-start;
		padding: 0 5px;
	}

	.common_view label,
	.common_view p {
		font-size: 14px;
		padding-bottom: 5px;
	}

	.passport_fees {
		padding: 4px 7px;
	}

	.passport_fees table td,
	.pay-recipt_table tr td,
	.pageWrapper table tr td {
		width: auto;
		text-align: left;
		border-bottom: 1px solid #d7d7d7 !important;
	}

	.passport_fees table td:nth-child(even),
	.pay-recipt_table tr td:nth-child(even) {
		float: right;
	}

	.info h3 {
		font-size: 14px;
		line-height: 1.2;
	}

	.half_w {
		width: 100%;
	}

	table.pay-recipt_table td {
		border-bottom: 0 !important;
		font-size: 0.87em;
	}

	table.pay-recipt_table tr td:last-child {
		text-align: right;
	}

	.cod-Data h2 {
		font-size: 20px;
	}

	.detail_change_view {
		padding: 0;
	}

	.margin-top {
		margin-top: 0 !important;
	}

	.personal_details_section table {
		width: 100%;
		border: 1px solid #c6c5c5;
	}

	.personal_details_section table tr {
		width: 100% !important;
		border-bottom: 0 !important;
	}

	.personal_details_section table tr td:first-child {
		width: 39%;
		padding-left: 5px;
		text-align: left;
	}

	.personal_details_section table tr td {
		width: auto;
		text-align: left;
		border-left: 1px solid #ddd;
	}

	.personal_details_section table tr td:nth-child(even) {
		width: 61%;
	}

	#progressbar li::before {
		width: 20px;
		height: 20px;
		line-height: 22px;
		display: block;
		font-size: 13px;
		margin: 12px auto 2px auto;
	}

	ul#progressbar p {
		line-height: 12px;
	}

	.grid-box {
		padding: 0 !important;
	}

	.cr-boundary {
		width: 100% !IMPORTANT;
	}

	.image_upload_container .modal-body {
		overflow-y: auto;
		/* height: auto; */
		max-height: 500px;
	}

	.grid-photo-two {
		width: 100%;
		float: left;
	}

	.issue_box {
		padding: 3px 8px;
		margin: 10px 0;
		height: auto;
	}

	.grid-guideline table tbody td:first-child,
	.grid-guideline table tbody td:last-child {
		text-align: left;
		width: 100%;
	}

	.guidelines-container,
	.cod-popup {
		width: calc(100% - 20px);
	}

	.grid-guideline .grid-boxs:last-child {
		padding-top: 30px;
	}

	.grid-guideline .grid-boxs:first-child {
		border: 1px solid #0d7836;
	}

	.tabs_vertical .wrapper {
		flex-wrap: wrap;
	}

	.tabs {
		width: 100%;
	}

	.panels {
		width: 100%;
	}

	.support_document table tr td .alert {
		position: inherit;
		padding-top: 0;
	}
	.main .main-heading {
		font-size: 12px;
	}
	.faq input {
		width: 10px;
		height: 10px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.grid_three {
		grid-template-columns: 49% 49%;
		grid-gap: 1%;
	}

	.nav-txt {
		max-width: 240px;
	}

	.banner-img-name {
		margin: 46px 0 7px;
	}

	.header_box {
		width: 130px;
	}

	.header_box.menu {
		width: calc(100% - 370px);
		justify-content: flex-end;
	}

	.nav-links li {
		margin-left: 5px;
	}
}

@media (max-height: 600px) {

	.image_upload_container .centered .grid-one-third,
	.image_upload_container .file-upload {
		height: auto;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.common_view_item {
		width: 50%;
	}

	.search_grid-upload-img .grid-box {
		width: 50%;
	}

	.image_upload_container .modal-body {
		max-height: 580px;
	}

}

@media (max-height: 700px) {
	.image_upload_container {
		max-height: calc(100vh - 50px);
	}

	.image_upload_container .modal-body {
		height: calc(100vh - 155px);
	}
}

@media only screen and (min-width: 480px) and (max-width: 575px) {

	.pay-info p {
		width: 50%;
	}	
	.pay-info p:last-child {
		text-align: right;
	}
}

@media only screen and (max-width: 575px) {
	.faq-text-list input {
		/* top: 2px; */
	}
	table.request_table td {
		padding: 5px 5px;
	}
	.faq-heading {
		font-size: 15px;
		padding-bottom: 5px;
	}
	.faq-text label {
		font-size: 12px;
	}

	.pay-info p:first-child, .pay-info p:last-child {
		padding: 0;
	}

	.header-main {
		padding: 0;
	}

	.header_box {
		width: 140px;
		order: 1;
		padding-left: 10px;
	}

	.nav-links li.track_btn {
		margin-left: 5px;
	}

	.header_box.menu {
		width: calc(100% - 140px);
		order: 2;
		padding-right: 10px;
	}

	.confirmbox-container {
		min-width: calc(100% - 20px)
	}

	.nav-txt {
		max-width: 100%;
		width: 100%;
		padding: 1px 10px 3px;
		order: 3;
		border-top: 1px solid #cacbca;
		font-size: 15px;
		text-shadow: 0 0 BLACK;
	}

	.grid_three {
		grid-template-columns: 100%;
		grid-gap: 0;
	}

	.grid_three .col_box {
		margin-bottom: 10px;
	}

	.track_application {
		display: block;
		text-align: right;
	}

	.banner-img-name {
		margin: 71px 0 9px;
	}

	.nav-links li {
		margin-left: 0;
		padding-left: 5px;
	}
	.payment-table {
		display: flex;
	}
	.payment-table tr {
		display: inline-block;
		width: 50%;
		padding: 0;
	}
	.pay-recipt table th {
		background: #fff;
		border-bottom: none;
	}
	.payment-table tr {
		border-bottom: none;
	}
	.payment-table tr:first-child {
		border-right: 1px solid #cacbca;
	}
	.payment-table tr th, .payment-table tr td {
		width: 100%;
		display: inline-block;
	}
	table.payment-table, .payment-table tr {
		padding: 0 !important;
	}
	.payment-table td, .payment-table th {
		text-align: left !important;
	}
	.pay-recipt table td, .pay-recipt table th {
		height: auto;
		padding: 5px 10px !important;
		font-family: "sfui-regular";
	}
	.pay-recipt table td {
		text-align: right !important;
	}
}

@media only screen and (max-width: 479px) {

	.profile-view .back_btn,
	.profile-view .save_btn,
	.profile-view .custom_btn,
	.profile-view .btn,
	.profile-view .receipt_btn,
	.profile-view .edit_btn {
		font-size: 10px;
		padding: 8px 5px;
		height: auto;
		margin: 10px 3px 10px 0;
	}
    .pay-info p {
        font-size: 11px;
		width: 100%;
		display: flex;
    	justify-content: space-between;
    }

	.upload_btn, button.filt--upload {
		font-size: 12px;
	}

	.pay-info p:first-child::before {
		display: none;
	}

	.notice_modal .modal-footer {
		justify-content: space-between;
	}

	.tab50-inner p {
		padding: 0;
	}

	.re_upload_box {
		padding-bottom: 10px;
	}

	.history_info-inner td {
		border-bottom: none;
		padding: 5px 5px;
	}

	.history_info-inner td .danger {
		font-size: 9px;
	}

	.reUpload input {
		margin-bottom: 2px;
	}

	.reUpload.icons_uplaod {
		text-align: left;
	}

	.tabordion-list {
		width: 120px;
	}

	.tabordion-output {
		width: calc(100% - 120px);
		padding: 0 0 0 30px;
	}

	.tabordion-list li {
		font-size: 14px;
		padding: 10px;
	}

	ul#progressbar p {
		font-size: 9px;
	}

	.common_view_item {
		width: 100%;
		display: flex;
	}

	.result-header .user_pic {
		border-right: none;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-bottom: 1px solid #ada7a7;
	}
	.result-header .user_pic img {
		border: 1px solid #ada7a7;
	}
	.upper_bar, .common_view {
		min-height: auto;
	}
	.s-head .result-header .user-info-detail p {
		font-size: 14px;
		text-align: right;
	}

	.grid-date {
		grid-template-columns: calc(33.3% - 10px) calc(40% - 10px) calc(30% - 10px);
		float: left;
	}

	ngx-super-croppie {
		width: 100%;
	}

	.back_btn,
	.save_btn,
	.custom_btn,
	.btn,
	.receipt_btn,
	.edit_btn {
		font-size: 12px;
		margin-bottom: 0;
	}

	.form-card {
		padding: 8px 10px;
	}

	.track_btn {
		padding: 5px 5px;
		min-height: auto;
	}

	.header_box span {
		font-size: 10px;
		max-width: 120px;
		line-height: 120%;
	}

	.img_re-upload-info label {
		width: 100%;
	}

	.re_upload_box p {
		width: 100%;
	}

	.img_re-upload-info label input {
		cursor: pointer;
		margin-bottom: 5px;
	}

	td.upload-support-doc {
		width: 55%;
	}

	td.upload-support-doc-btn {
		width: 45%;
	}
}

@media only screen and (max-width: 450px) {
	.image-upload-large .modal-header h4 {
		font-size: 10px;
	}
}




@media print {
	.page-break {page-break-after: always;}
}